import axios from 'axios';
import { apiV1 } from 'configs/api';

const SemAnalytics = {
  // apiAccessToken: null,
  // accessTokenIsExpired() {
  //   return this.apiAccessToken
  //     && this.apiAccessToken.expires_at
  //     && this.apiAccessToken.expires_at < Date.now();
  // },
  async updateIdMap(key, value) {
    // if (!this.apiAccessToken || this.accessTokenIsExpired()) {
    //   this.apiAccessToken = await this.getApiAccessToken();
    // }
    const response = await axios.put(apiV1('id-map/mine'), {
      params: {
        key,
        value,
      },
    });
    return response.data;
  },
  // TODO: individual sagas and services for individual aspects
  // e.g. enrollment, finder, google analytics, etc
  async getData(params) {
    if (params.type === 'application_history') {
      return this.getApplicationData(params);
    }

    if (params.type === 're_enrollment_history') {
      return this.getReEnrollmentData(params);
    }

    if (params.type === 'total_enrollment_history') {
      return this.getTotalEnrollmentData(params);
    }

    if (params.type === 'google_analytics') {
      return this.getPageviewsWithSources(params);
    }

    if (params.type === 'google_analytics_summary') {
      return this.getStatsByPage(params);
    }

    if (params.type === 'facebook') {
      return this.getFacebookData(params);
    }

    if (params.type === 'finder') {
      return this.getFinderData(params);
    }

    if (params.type === 'finder_by_location') {
      return this.getFinderDataByLocation(params);
    }

    if (params.type === 'greatschools') {
      // FIXME: this averting this call temporarily because of rate limit errors
      return {};
      return this.getGreatSchoolsData(params);
    }

    if (params.type === 'applications_with_reputations') {
      return this.getReputationData(params);
    }

    if (params.type === 'applications_by_program_year_over_year') {
      return this.getApplicationsByProgramYearOverYear(params);
    }

    if (params.type.startsWith('applications_by_program')) {
      return this.getApplicationsByProgram(params);
    }

    if (params.type.startsWith('applications_by_finder_program')) {
      return this.getApplicationsByProgram(params);
    }

    if (params.type.startsWith('applications_by_school') ||
        params.type.startsWith('applications_by_zone_school')) {
      return this.getApplicationsBySchool(params);
    }

    if (params.type === 'zones') {
      return this.getZones(params);
    }

    if (params.type === 'schools') {
      return this.getSchools(params);
    }

    if (params.type === 'grades') {
      return this.getGrades(params);
    }

    if (params.type === 'programs') {
      return this.getPrograms(params);
    }

    if (params.type === 'program_types') {
      return this.getProgramTypes(params);
    }

    throw new Error(`Programmer Error: unimplemented report type ${params.type}`);
  },
  async getTestData() {
    // if (!this.apiAccessToken || this.accessTokenIsExpired()) {
    //   this.apiAccessToken = await this.getApiAccessToken();
    // }
    //   TODO: axios middleware to check for expired tokens?
    //   or generic method to check token and send request
    const response = await axios.get(apiV1('athena-test'), {
    });
    return response.data;
  },
  async getApplicationData(params) {
    return axios.get(apiV1('enrollment/applications'), {
      params,
    });
  },
  async getReEnrollmentData(params) {
    return axios.get(apiV1('enrollment/re-enrollments'), {
      params,
    });
  },
  async getTotalEnrollmentData(params) {
    return axios.get(apiV1('enrollment/total-enrollments'), {
      params,
    });
  },
  async getPageviews(params) {
    return axios.get(apiV1('google-analytics/pageviews'), {
      params,
    });
  },
  async getPageviewsWithSources(params) {
    return axios.get(apiV1('google-analytics/sources'), {
      params,
    });
  },
  async getStatsByPage(params) {
    return axios.get(apiV1('google-analytics/stats-by-page'), {
      params,
    });
  },
  async getFacebookData(params) {
    return axios.get(apiV1('facebook/post-data'), {
      params,
    });
  },
  async getFinderData(params) {
    return axios.get(apiV1('finder/searches'), {
      params,
    });
  },
  async getFinderDataByLocation(params) {
    return axios.get(apiV1('finder/searches-by-location'), {
      params
    });
  },
  async getApplicationsBySchool(params) {
    return axios.get(apiV1('enrollment/applications-by-school'), {
      params,
    });
  },
  async getApplicationsByProgram(params) {
    return axios.get(apiV1('enrollment/applications-by-program'), {
      params,
    });
  },
  async getApplicationsByProgramYearOverYear(params) {
    return axios.get(apiV1('enrollment/applications-by-program-year-over-year'), {
      params,
    });
  },
  async getZones(params) {
    return axios.get(apiV1('enrollment/zones'), {
      params,
    });
  },
  async getSchools(params) {
    return axios.get(apiV1('enrollment/schools'), {
      params,
    });
  },
  async getGrades(params) {
    return axios.get(apiV1('enrollment/grades'), {
      params,
    });
  },
  async getPrograms(params) {
    return axios.get(apiV1('enrollment/programs'), {
      params,
    });
  },
  async getProgramTypes(params) {
    return axios.get(apiV1('enrollment/program-types'), {
      params,
    });
  },
  async getGreatSchoolsData(params) {
    return axios.get(apiV1('great-schools/ratings'), {
      params,
    });
  },
  async getNearbyGreatSchoolsData(params) {
    return axios.get(apiV1('great-schools/nearby-ratings'), {
      params,
    });
  },
  async getReputationData(params) {
    return axios.get(apiV1('reputation/schools-with-competitors'), { params })
  }
};

export default SemAnalytics;
